class d20190107a{
    constructor() {
        this.config();
    }

    config() {
        this.cfg = {
            pluses: /\+/g,
            raw: true,
            json: false
        };
    }

    encode(s) {
        return this.cfg.raw ? s : encodeURIComponent(s);
    }

    decode(s) {
        return this.cfg.raw ? s : decodeURIComponent(s);
    }

    stringifyCookieValue(value) {
        return this.encode(this.cfg.json ? JSON.stringify(value) : String(value));
    }

    parseCookieValue(s) {
        if (s.indexOf('"') === 0) {
            s = s.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, '\\');
        }

        try {
            s = decodeURIComponent(s.replace(this.cfg.pluses, ' '));
            return this.cfg.json ? JSON.parse(s) : s;
        } catch (e) {

        }

    }

    isFunction(fn) {
        return typeof fn === 'function';
    }

    read(s, converter) {
        const value = this.cfg.raw ? s : this.parseCookieValue(s);
        return this.isFunction(converter) ? converter(value) : value;
    }

    getAll() {
        const cookies = document.cookie ? document.cookie.split('; ') : [];
        let data = new Map();
        for (let i = 0, l = cookies.length; i < l; i++) {
            let parts = cookies[i].split('=');
            let name = this.decode(parts.shift());
            let cookie = parts.join('=');
            data.set(name, this.read(cookie));
        }
        return data;
    }

    get(key) {
        const cookies = this.getAll();
        return cookies.has(key) ? cookies.get(key) : null;
    }


    set(key, value, options = {}) {

        options = Object.assign({}, options);

        if (typeof options.expires === 'number') {
            let days = options.expires,
                t = options.expires = new Date();
            t.setTime(+t + days * 864e+5);
        }

        return (document.cookie = [
            this.encode(key), '=', this.stringifyCookieValue(value),
            options.expires ? '; expires=' + options.expires.toUTCString() : '',
            options.path ? '; path=' + options.path : '',
            options.domain ? '; domain=' + options.domain : '',
            options.secure ? '; secure' : ''
        ].join(''));

    }

    remove(key) {
        if (!this.get(key)) {
            return false;
        } else {
            return this.set(key, '', {expires: -1});
        }
    }

}



class d20191007b {

    constructor(d20191007a) {
        this.d20191007a = d20191007a;
        this.storageName = 'cookieStorage';
        this.agree = 'agree5';
        document.addEventListener('DOMContentLoaded', e => this.ready());
    }

    ready() {
        const agree = this.getData(this.agree) * 1;
        const div = document.querySelector('.cookie');
        console.log(`agree=${agree}`);

        switch (agree) {
            case 1:
                if (div !== null) {
                    div.parentNode.removeChild(div);
                }
                break;
            case 2:
                if (div !== null) {
                    div.parentNode.removeChild(div);
                }
                //setInterval(() => this.cleanCookies(), 3000);
                break;
            default:
                if (div !== null) {
                    div.style.display = '';
                    const as = div.querySelectorAll('a');

                    as[1].addEventListener('click', e => {
                        this.setData(this.agree, 1);
                        div.remove();
                        return e.preventDefault();
                    });
                    as[2].addEventListener('click', e => {
                        div.parentNode.removeChild(div);
                        this.setData(this.agree, 2);
                        this.cleanCookies();

                        return true;
                    });
                }

        }
    }

    cleanCookies() {

        this.d20191007a.getAll().forEach((value, name) => {
            console.debug(name, value);
            if (name !== 'PHPSESSID' && name !== 'cookie_a2016') {
                this.d20191007a.remove(name);
            }
        });
    }

    getData(name) {
        const data = this.data();
        return data.hasOwnProperty(name) ? data[name] : null;
    }

    data() {
        let s = window.localStorage.getItem(this.storageName);
        let json;
        try {
            json = JSON.parse(s);
        } catch (e) {
            json = {};
        }
        return json === null ? {} : json;
    }

    setData(name, value) {
        const data = this.data();
        data[name] = value;
        window.localStorage.setItem(this.storageName, JSON.stringify(data));
    }
}


new d20191007b(new d20190107a());
